import find from "lodash/find";
import filter from "lodash/filter";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import _orderBy from "lodash/orderBy";
import sortBy from "lodash/sortBy";

import { db } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "../../Common/utils/firebaseFunctions";
import {
  ViewWLSubscriptionsTiers,
  WLSubscriptionsTiers,
} from "../../Common/models/subscriptions";
import { SUBSCRIPTIONS } from "../constants/dbCollections";
import { getStripeSubscriptions } from "../api/stripe";
import { WLStripeSubscription } from "../../Common/models/stripe";

export const getSubscriptionListStripe = async () => {
  const subscriptionQuery = await getDocs(
    query(collection(db, SUBSCRIPTIONS), where("platform", "==", "stripe"))
  );

  const subscriptionConfigData = subscriptionQuery.docs.map(
    (doc) =>
      ({
        docId: doc.id,
        ...doc.data(),
      } as WLSubscriptionsTiers)
  );

  // const productQueryIdentifiers = subscriptionConfigData.map(
  //   (data) => data.productQueryIdentifier
  // );

  const stripeSubscriptions = await getStripeSubscriptions();
  const subscriptionObjects = stripeSubscriptions.data
    .result as WLStripeSubscription[];

  const result = filter(
    subscriptionObjects.map((product) => {
      const existingSubscriptionConfig = find(
        subscriptionConfigData,
        (sub) => sub.docId === product.id
      );

      if (existingSubscriptionConfig === undefined) {
        return null;
      }

      return {
        ...existingSubscriptionConfig,
        isOneOff: !!existingSubscriptionConfig.isOneOff,
        stripePriceId: product.priceId,
        price: parseInt(product.price),
        priceString: `$${product.price}`,
      } as ViewWLSubscriptionsTiers;
    }),
    (sub) =>
      !isNull(sub) &&
      !sub.isOneOff &&
      sub.name.toLowerCase().trim() !== "vip" &&
      sub.name.toLowerCase().trim() !== "legend"
  );

  return compact(sortBy(result, "price"));
};

export const getSubscription = async (id: string) => {
  if (isEmpty(id)) {
    return null;
  }
  const query = await getDoc(doc(db, SUBSCRIPTIONS, id));
  if (!query.exists()) {
    return null;
  }

  return {
    docId: query.id,
    ...query.data(),
  } as WLSubscriptionsTiers;
};

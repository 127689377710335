import { memo } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import s from "./StripePaymentForm.module.scss";
import { stripeKey } from "../../../../firebase";
import StripePaymentForm from "./StripePaymentForm";

const stripePromise = loadStripe(stripeKey);

const StripeForm = ({ clientSecret }: { clientSecret: string }) => {
  return (
    <div className={s.container}>
      <Elements
        stripe={stripePromise}
        options={{
          locale: "en-AU",
          clientSecret,
        }}
      >
        <StripePaymentForm />
      </Elements>
    </div>
  );
};

export default memo(StripeForm);

import cx from "classnames";
import { memo } from "react";

import { WLButton } from "../../../../../Common/components/WLButton/WLButton";
import {
  WLFormInput,
  WLFormInputProps,
} from "../../../../../Common/components/WLForms/WLFormInput/WLFormInput";
import useWebSignupStore from "../../store";
import { useStepSubscriptionProvider } from "../provider";
import s from "./styles.module.scss";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";

interface iPromoCodeSection {
  promoField: WLFormInputProps;
  validatePromoCode: (
    overrideCode?: string
  ) => Promise<true | "Invalid promo code">;
  useFormMethods: UseFormReturn<FieldValues, any, undefined>;
}
const PromoCodeSection = ({
  promoField,
  validatePromoCode,
  useFormMethods,
}: iPromoCodeSection) => {
  const { selectedSubscription } = useWebSignupStore();

  return (
    <FormProvider {...useFormMethods}>
      {selectedSubscription !== undefined && (
        <form
          className={s.container}
          onSubmit={(event) => event.preventDefault()}
        >
          <WLFormInput className={cx(s.promoCodeInput)} {...promoField} />
          <WLButton
            onClick={() => {
              validatePromoCode();
            }}
            className={cx(
              s.applyBtn,
              "wl-body-6 red-submit-button ion-no-margin"
            )}
            isFormComponent
          >
            Apply
          </WLButton>
        </form>
      )}
    </FormProvider>
  );
};

export default memo(PromoCodeSection);

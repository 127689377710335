import { IonSkeletonText } from "@ionic/react";

export const WLSkeleton = ({
  width,
  height,
}: {
  width: string;
  height: string;
}) => (
  <IonSkeletonText
    style={{
      width,
      height,
      borderRadius: "5px",
    }}
    animated
  />
);

import {
  IonContent,
  IonImg,
  IonLabel,
  IonModal,
  IonPage,
  useIonRouter,
} from "@ionic/react";
import cx from "classnames";

import { Suspense, lazy, memo, useRef } from "react";
import { WLButton } from "../../../../../Common/components/WLButton/WLButton";
import { isMobile } from "../../../../../Common/utils/common";
import s from "./styles.module.scss";
import brandLogo from "../../../../../Common/assets/images/brand-logo.svg";
import whistle from "../../../../../Common/assets/logo/starter.png";
import crown from "../../../../../Common/assets/logo/mvp.png";
import medal from "../../../../../Common/assets/logo/pro.png";
import selectedIcon from "../../../../assets/images/selected-icon.png";
import useWebSignupStore from "../../store";

import isNull from "lodash/isNull";
import { ViewWLSubscriptionsTiers } from "../../../../../Common/models/subscriptions";
import {
  getStripePriceWithPromo,
  getWLPromoDescription,
} from "../../../../services/promo";

import { WLSkeleton } from "../../../../../Common/components/WLSkeleton/WLSkeleton";
import { SubscriptionType } from "../../../../constants/subscriptionCardConst";

const SignUpFooter = lazy(
  () => import("../../../../../Common/components/SignUpFooter/SignUpFooter")
);
interface iChooseMembership {
  updateSubscription: (subscription: ViewWLSubscriptionsTiers) => void;
}

const ChooseMembership = ({ updateSubscription }: iChooseMembership) => {
  const history = useIonRouter();
  const {
    selectedSubscription,
    validStripeCoupon,
    validPromoCodePromo,
    setStep,

    membershipsModalOpen,
    setMembershipsModalOpen,

    subscriptionList,
  } = useWebSignupStore();

  const modal = useRef<HTMLIonModalElement>(null);

  const wlPromoDescription = (sub: ViewWLSubscriptionsTiers) => {
    return !isNull(validPromoCodePromo) &&
      selectedSubscription !== undefined &&
      !isNull(selectedSubscription) &&
      validPromoCodePromo !== undefined
      ? getWLPromoDescription(validPromoCodePromo, sub)
      : "";
  };

  const dismiss = () => {
    modal.current?.dismiss();
    setMembershipsModalOpen(false);
  };

  const getMembership = (subscription: ViewWLSubscriptionsTiers) => {
    // setSelectedSubscription(subscription);

    updateSubscription(subscription);
    dismiss();
  };

  const styles = {
    Starter: {
      cardIcon: whistle,
      cardStyle: s.silverCard,
      cardIconStyle: s.whistleIcon,
      dividerStyle: "",
    },
    Pro: {
      cardIcon: medal,
      cardStyle: s.blueCard,
      cardIconStyle: s.medalIcon,
      dividerStyle: "",
    },
    MVP: {
      cardIcon: crown,
      cardStyle: s.goldCard,
      cardIconStyle: s.crownIcon,
      dividerStyle: s.blackDivider,
    },
  };

  const renderCard = (subscription: ViewWLSubscriptionsTiers) => {
    const name = (subscription.name ?? "Starter") as SubscriptionType;
    console.log("checking name =--", name);
    const isSelected = selectedSubscription?.name === name;
    const { cardStyle, cardIcon, cardIconStyle, dividerStyle } = styles[name];

    return (
      <div
        key={name}
        className={cx(s.card, cardStyle, isSelected ? s.selectedCard : "")}
      >
        {!isMobile() && (
          <div className={s.cardIconsSection}>
            <IonImg
              src={cardIcon}
              className={cx(s.legendIcon, cardIconStyle)}
            />
            {isSelected && (
              <IonImg src={selectedIcon} className={s.selectedIcon} />
            )}
          </div>
        )}
        <div className={s.cards}>
          <div className={s.cardNameSection}>
            <IonLabel className={s.subscriptionName}>
              {name.toUpperCase()}
            </IonLabel>
            {isSelected && isMobile(true) && (
              <IonImg src={selectedIcon} className={s.selectedIcon} />
            )}
          </div>
          <IonLabel className={cx(s.subscriptionDescription, "wl-body-6")}>
            Get {subscription.entriesPerMonth} accumulating monthly entries
          </IonLabel>
          <IonLabel className={s.subscriptionPrice}>
            {!isNull(validStripeCoupon) && (
              <s className={s.smallPrice}>{subscription.priceString}</s>
            )}
            <IonLabel className={s.mainPrice}>
              {!isNull(selectedSubscription) &&
              validStripeCoupon !== undefined &&
              !isNull(validStripeCoupon)
                ? getStripePriceWithPromo(subscription.price, validStripeCoupon)
                : subscription.priceString}
            </IonLabel>
            <span className={cx(s.pricePerMonth, "wl-body-6")}>per/month</span>
          </IonLabel>
          <WLButton
            onClick={() => {
              if (isSelected) {
                dismiss();
                console.log("will just dismiss");
              } else {
                getMembership(subscription);
              }
            }}
            className={cx(s.getMembershipBtn, "wl-body-6 red-submit-button")}
          >
            Get {name.toUpperCase()} membership
          </WLButton>
          <div className={cx(s.divider, dividerStyle)} />
          {!isNull(wlPromoDescription(subscription)) && (
            <IonLabel className={cx(s.entryDescription, "ion-text-capitalize")}>
              {wlPromoDescription(subscription)}
            </IonLabel>
          )}
        </div>
      </div>
    );
  };

  return (
    <IonModal
      className={s.membershipsModal}
      isOpen={membershipsModalOpen}
      onDidDismiss={dismiss}
    >
      <IonPage className={s.page}>
        <IonContent className={s.content} fullscreen>
          {!isMobile() && <IonImg src={brandLogo} className={s.brandLogo} />}
          <IonLabel className={cx(s.headerTitle, "wl-h2-winnersans")}>
            CHOOSE THE MEMBERSHIP THAT IS RIGHT FOR YOU
          </IonLabel>
          <div className={s.main}>
            {subscriptionList?.length ? (
              subscriptionList?.map((subscription) => {
                return renderCard(subscription);
              })
            ) : (
              <>
                <WLSkeleton width="300px" height="320px" />
                <WLSkeleton width="300px" height="320px" />
                <WLSkeleton width="300px" height="320px" />
              </>
            )}
          </div>
          <div className={s.footer}>
            <Suspense>
              <SignUpFooter />
            </Suspense>
          </div>
        </IonContent>
      </IonPage>
    </IonModal>
  );
};

export default memo(ChooseMembership);

import find from "lodash/find";
import Bugsnag from "@bugsnag/js";
import { ENVIRONMENTS } from "../../Common/config";

const ignoredHandledErrors = [
  "Promo code is invalid", // promo code checking
  "Request timed out. The device maybe offline.", //slow internet
  "Failed to get document because the client is offline.", // slow internet
  "Connection lost. Please try again.", // slow internet
  "The network connection was lost.", // slow internet
  "An account with this email address already exists.",
  "No user found. Please login.",
];

export const ignoredUnhandledErrors = [
  "Connection to Indexed Database server lost. Refresh the page to try again", // slow internet
  "Cannot read properties of undefined (reading 'classList')", // node module issue from ionic
  "undefined is not an object (evaluating 'nu.toolbars[nu.toolbars.length-1].el')", // node module issue from ionic
  "undefined is not an object (evaluating 'uu.classList')", // node module issue from ionic
  "Can't find variable: AutoFillPopupClose",
];
export const bugNotif = (funcName: string, message: string) => {
  if (
    [
      ENVIRONMENTS.local,
      ENVIRONMENTS.localProduction,
      ENVIRONMENTS.development,
    ].indexOf(import.meta.env.MODE) === -1
  ) {
    try {
      const ignoredError = find(
        ignoredHandledErrors,
        (error) => error.trim().toLowerCase() === message.toLowerCase().trim()
      );
      if (ignoredError === undefined) {
        // console.log("should send notif");
        Bugsnag.notify(new Error(`${funcName} - ${message}`));
      }
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      console.log("SILENT ERROR -- bugNotif", error.message || error);
    }
  }
};

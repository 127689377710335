import React, { FC } from "react";
import cx from "classnames";
import s from "./SuccessCheckEmail.module.scss";
import { WLButton } from "../../../../Common/components/WLButton/WLButton";

interface SuccessCheckEmail {
  handleResendEmail: () => void;
}
export const SuccessCheckEmail: FC<SuccessCheckEmail> = ({
  handleResendEmail,
}) => {
  return (
    <div className={s.successEmail}>
      <div className={s.container}>
        <div className={`wl-h3 ${s.title}`}>CHECK YOUR EMAIL</div>
        <div className={`wl-body-1 ${cx(s.description, s.compress)}`}>
          We have sent a password recover instructions to your email.
        </div>
      </div>

      <div className={cx(s.container, s.resend)}>
        <div className={`wl-h3 ${s.title}`}>DID NOT RECEIVE THE EMAIL?</div>
        <div className={`wl-body-1 ${s.description}`}>
          Check your spam filter or try again.
        </div>
        <WLButton className={s.actionBtns} onClick={handleResendEmail}>
          Resend email
        </WLButton>
      </div>
    </div>
  );
};

import compact from "lodash/compact";
import debounce from "lodash/debounce";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import Stripe from "stripe";
import { useEffect, useRef } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import useWebSignupStore from "../store";
import { bugNotif } from "../../../api/bugsnag";
import { isPromoValid } from "../../../services/promo";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { useFormsStore } from "../../../../Common/store/common";
import {
  createStripeSubscriptionV2,
  validateStripePromo,
} from "../../../api/stripe";
import {
  getErrorMsg,
  useEffectOnlyOnce,
} from "../../../../Common/utils/common";
import { WLFormInputProps } from "../../../../Common/components/WLForms/WLFormInput/WLFormInput";
import { ViewWLSubscriptionsTiers } from "../../../../Common/models/subscriptions";
import {
  ENVIRONMENTS,
  STRIPE_SUBSCRIPTION_ID_MAP,
  STRIPE_SUBSCRIPTION_ID_MAP_DEV_STG,
} from "../../../../Common/config";
import {
  addtoCartKlaviyoEvent,
  sendFbqCart,
  sendSnapAddToCart,
  sendttqAddToCart,
} from "../../../services/user";

export const useStepSubscriptionProvider = () => {
  const pageParams = useParams();
  const {
    emailAddress,
    stripeId,

    validPromoCode,
    setValidPromoCode,

    validStripeCoupon,
    setValidStripeCoupon,

    setValidPromoCodePromo,

    subscriptionList,
    setSubscriptionList,

    selectedSubscription,
    setSelectedSubscription,

    stripeClientSecretId,
    setStripeClientSecretId,
  } = useWebSignupStore();

  const stripeClientSecretIdRef = useRef(stripeClientSecretId);
  stripeClientSecretIdRef.current = stripeClientSecretId;

  const { setFormLoading } = useFormsStore();

  // // GET OPTIONS FOR SUBSCRIPTION
  // const { data: subscriptionList, error } = useQuery({
  //   queryKey: ["GET_SUBSCRIPTION_WEB_SIGNUP"],
  //   queryFn: async () => {
  //     const resp = await getSubscriptions(false, true);
  //     return orderBy(resp, (sub) => sub.price, "asc");
  //   },
  //   refetchOnWindowFocus: false,
  // });

  // // To handle subscription list get error
  // useEffect(() => {
  //   if (!isNull(error)) {
  //     bugNotif(
  //       "useStepSubscriptionProvider - getSubscriptions",
  //       getErrorMsg(error)
  //     );
  //   }
  // }, [error]);

  // To handle selected subscription.
  useEffect(() => {
    if (subscriptionList !== undefined) {
      if (selectedSubscription === undefined || isNull(selectedSubscription)) {
        const { subscriptionKey } = pageParams as {
          subscriptionKey: string | undefined;
        };

        const selectedSubscriptionId =
          import.meta.env.MODE === ENVIRONMENTS.production
            ? STRIPE_SUBSCRIPTION_ID_MAP[subscriptionKey || "pro"]
            : STRIPE_SUBSCRIPTION_ID_MAP_DEV_STG[subscriptionKey || "pro"];

        updateSelectedSubscription(
          find(
            subscriptionList,
            (sub) => sub.docId === selectedSubscriptionId
          ) || subscriptionList[1]
        );

        setSubscriptionList(subscriptionList);
      }
    }
  }, [subscriptionList]);

  // to handle update subscription selection. Mostly used by upsell component.
  // Can be used also when changing plans
  const updateSelectedSubscription = (
    subscription: ViewWLSubscriptionsTiers
  ) => {
    setSelectedSubscription(subscription);

    const pid = subscription.docId;
    const value = subscription.price;
    sendFbqCart("Membership", pid, value);
    sendSnapAddToCart(emailAddress, "Membership", pid, value);
    sendttqAddToCart(emailAddress, pid, value);
    addtoCartKlaviyoEvent(pid, value);
  };

  const useFormMethods = useForm();
  const { watch, setValue, trigger, setError, clearErrors } = useFormMethods;

  // to validate internal promo
  const validateWLPromoCode = async (promoCode: string) => {
    setValidPromoCodePromo(undefined);
    setValidPromoCode(undefined);
    try {
      const result = await isPromoValid({
        code: promoCode,
        forSignUp: true,
      });
      const testedPromoCode = !isNull(result) ? result.code : "";
      setValidPromoCodePromo(result);
      setValidPromoCode(testedPromoCode);
      console.log("got valid promo!", testedPromoCode);
      return !isEmpty(testedPromoCode);
    } catch (error) {
      setValidPromoCodePromo(null);
      setValidPromoCode("");
      bugNotif("debouncedValidatePromo", getErrorMsg(error));
      return false;
    }
  };

  // to validate stripe coupon
  const validateStripePromoCode = async (promoCode: string) => {
    setValidStripeCoupon(undefined);
    try {
      const result = await validateStripePromo({
        promoCode,
      });
      const coupon = result.data.coupon as Stripe.Coupon;
      console.log(
        "now checking stripe coupon against ",
        coupon,
        selectedSubscription
      );
      if (
        coupon.applies_to !== undefined &&
        selectedSubscription !== undefined &&
        !isNull(selectedSubscription) &&
        coupon.applies_to.products.indexOf(selectedSubscription.docId) === -1
      ) {
        setValidStripeCoupon(null);
        return false;
      }
      // console.log("got valid stripe promo!", coupon);

      setValidStripeCoupon(coupon);

      return true;
    } catch (error) {
      setValidStripeCoupon(null);
      bugNotif("debouncedValidatePromo", getErrorMsg(error));
      return false;
    }
  };

  const promoFieldRef = useRef("");
  const promoField: WLFormInputProps = {
    label: "Promo Code",
    name: "promoCode",
    type: "text",
    disabled:
      // this is being disabled if promo, coupon or stripeClient secret id is undefined. Which indicates payment form are still loading.
      // disabled to avoid triggering validation.
      validPromoCode === undefined ||
      validStripeCoupon === undefined ||
      stripeClientSecretId === undefined,
    className: "ion-text-uppercase ion-text-start",
    placeholder: "Enter promo code",
  };
  promoFieldRef.current = watch(promoField.name, "");

  // to validate promo and stripe coupon simultaneously
  const validatePromoCode = async (overrideCode = "") => {
    setFormLoading(true);
    const value = overrideCode || promoFieldRef.current;
    const code = (value || "").trim().toUpperCase();
    // console.log("checking --- ", { code, overrideCode });
    const validationResults = await Promise.all([
      validateWLPromoCode(code),
      validateStripePromoCode(code),
    ]);

    setFormLoading(false);

    const result =
      compact(validationResults).length > 0 ||
      isEmpty(code) ||
      "Invalid promo code";
    if (typeof result === "string") {
      setError(promoField.name, { type: "custom", message: result });
    } else {
      clearErrors();
    }
    console.log("got result result", result, validationResults, value);
    // refresh stripe form here get another clientsecretid after update

    return result;
  };

  // Initial handling if promo code is not undefined on load
  useEffectOnlyOnce(() => {
    const promoCode = new URLSearchParams(window.location.search).get("promo");
    if (!isNull(promoCode) && !isEmpty(promoCode)) {
      const promo = promoCode.toUpperCase().trim();
      setValue(promoField.name, promo);
      // trigger(promoField.name);
      console.log("will check promo from query");
      validatePromoCode(promo);
    } else {
      setValidPromoCode("");
      setValidStripeCoupon(null);
      setValidPromoCodePromo(null);
    }
  });

  // get clientSecretId to display payment form.
  const getStripeClientSecretId = async ({
    stripeId,
    priceId,
    stripCouponId,
    wlPromoCode,
  }: {
    stripeId: string;
    priceId: string;
    stripCouponId: string;
    wlPromoCode: string;
  }) => {
    console.log("recreating stripe client secret", {
      stripeId,
      priceId,
      stripCouponId,
      wlPromoCode,
    });
    const result = await createStripeSubscriptionV2(
      stripeId,
      priceId,
      stripCouponId,
      wlPromoCode
    );

    return {
      clientSecretId: result.data.clientSecret,
    };
  };

  let tries = 0;
  const { mutate: onGetStripeClientSecretId } = useMutation({
    mutationFn: getStripeClientSecretId,
    onMutate: () => {
      if (stripeClientSecretIdRef.current !== undefined) {
        setStripeClientSecretId(undefined);
      }
    },
    onSuccess: (response, vars) => {
      console.log("checking on success -- ", stripeClientSecretIdRef.current);
      if (
        isEmpty(stripeClientSecretIdRef.current) ||
        isNull(stripeClientSecretIdRef.current) ||
        stripeClientSecretIdRef.current === undefined
      ) {
        console.log("got stripe id --- ", response.clientSecretId);
        setStripeClientSecretId(response.clientSecretId);
      } else {
        if (tries < 4) {
          tries += 1;
          setTimeout(() => {
            onGetStripeClientSecretId(vars);
          }, 250);
        } else {
          throw new Error("Something went wrong please try again.");
        }
      }
    },
    onError: (error: any) => {
      setStripeClientSecretId(null);
      console.log("error creating stripe secret id", getErrorMsg(error));
      bugNotif("getStripeClientSecretId", getErrorMsg(error));
    },

    // retry: 3,
    // retryDelay: 250,
  });

  // Need to revalidate promo if selected subscription changes since some stripe coupon doesnt apply on some plans
  useEffect(() => {
    if (selectedSubscription !== undefined && !isNull(selectedSubscription)) {
      console.log("will trigger promo field for", selectedSubscription.name);
      trigger(promoField.name);
    }
  }, [selectedSubscription]);

  const debouncedOnGetStripeClientSecretId = debounce(
    ({
      stripeId,
      priceId,
      stripCouponId,
      wlPromoCode,
    }: {
      stripeId: string;
      priceId: string;
      stripCouponId: string;
      wlPromoCode: string;
    }) => {
      console.log("calling from debounced", {
        stripeId,
        priceId,
        stripCouponId,
        wlPromoCode,
      });
      onGetStripeClientSecretId({
        stripeId,
        priceId,
        stripCouponId,
        wlPromoCode,
      });
    },
    250
  );

  // Re-create stripe payment form if coupon, promo or selected subscription changes.
  useEffect(() => {
    // console.log("checking for stripe creation", {
    //   stripeId,
    //   selectedSubscription,
    // });

    if (
      !isEmpty(stripeId) &&
      selectedSubscription !== undefined &&
      !isNull(selectedSubscription) &&
      validStripeCoupon !== undefined &&
      validPromoCode !== undefined
    ) {
      console.log("useeffecttriggered", {
        stripeId,
        selectedSubscription,
        validStripeCoupon,
        validPromoCode,
      });
      debouncedOnGetStripeClientSecretId({
        stripeId,
        priceId: selectedSubscription.stripePriceId || "",
        stripCouponId: !isNull(validStripeCoupon) ? validStripeCoupon.id : "",
        wlPromoCode: !isEmpty(validPromoCode) ? validPromoCode : "",
      });
    }
  }, [stripeId, selectedSubscription, validPromoCode, validStripeCoupon]);

  return {
    updateSelectedSubscription,
    // pass if has upgrade option else, null
    upsellSubscription:
      selectedSubscription !== undefined &&
      !isNull(selectedSubscription) &&
      subscriptionList !== undefined &&
      subscriptionList.length > 1
        ? subscriptionList[
            findIndex(
              subscriptionList,
              (sub) => sub.docId === selectedSubscription.docId
            ) + 1
          ] || null
        : null,
    promoField,
    useFormMethods,
    validatePromoCode,
  };
};

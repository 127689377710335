import StripeJS from "stripe";
import { create } from "zustand";
import { ViewWLSubscriptionsTiers } from "../../../Common/models/subscriptions";
import { Promo } from "../../../Common/models/promos";
import { SignInProvider } from "../../../Common/models/user";

interface iWebSignUpSteps {
  [step: string]: number;
}
export const WEB_SIGNUP_STEPS = {
  email: 1,
  password: 2,
  completeProfile: 3,
  subscription: 4,
} satisfies iWebSignUpSteps;

interface WebSignUpState {
  // Index Page
  step: number;
  setStep: (step: number) => void;

  // 1st step
  emailAddress: string;
  setEmailAddress: (value: string) => void;

  signInProvider: SignInProvider | null;
  setSignInProvider: (state: SignInProvider) => void;

  // 2nd step
  password: string;
  setPassword: (value: string) => void;

  // 3rd step
  firstName: string;
  setFirstName: (value: string) => void;

  lastName: string;
  setLastName: (value: string) => void;

  phoneNumber: string;
  setPhoneNumber: (value: string) => void;

  userState: string;
  setUserState: (value: string) => void;

  // last step
  stripeId: string;
  setStripeId: (value: string) => void;

  userId: string;
  setUserId: (value: string) => void;

  subscriptionList: ViewWLSubscriptionsTiers[];
  setSubscriptionList: (value: ViewWLSubscriptionsTiers[]) => void;

  selectedSubscription: undefined | null | ViewWLSubscriptionsTiers;
  setSelectedSubscription: (value: null | ViewWLSubscriptionsTiers) => void;

  validPromoCode: string | undefined;
  setValidPromoCode: (value: string | undefined) => void;

  validPromoCodePromo: Promo | null | undefined;
  setValidPromoCodePromo: (value: Promo | null | undefined) => void;

  validStripeCoupon: undefined | null | StripeJS.Coupon;
  setValidStripeCoupon: (value: StripeJS.Coupon | null | undefined) => void;

  stripeClientSecretId: undefined | null | string;
  setStripeClientSecretId: (value: undefined | null | string) => void;

  submitPayment: () => void;
  setSubmitPayment: (func: () => void) => void;

  membershipsModalOpen: boolean;
  setMembershipsModalOpen: (value: boolean) => void;
}

const useWebSignupStore = create<WebSignUpState>((set) => ({
  step: WEB_SIGNUP_STEPS.email,
  setStep: (step) => set({ step }),

  emailAddress: "",
  setEmailAddress: (emailAddress) => set({ emailAddress }),

  signInProvider: null,
  setSignInProvider: (signInProvider) => set({ signInProvider }),

  password: "",
  setPassword: (password) => set({ password }),

  firstName: "",
  setFirstName: (firstName) => set({ firstName }),

  lastName: "",
  setLastName: (lastName) => set({ lastName }),

  phoneNumber: "",
  setPhoneNumber: (phoneNumber) => set({ phoneNumber }),

  userState: "",
  setUserState: (userState) => set({ userState }),

  stripeId: "",
  setStripeId: (stripeId) => set({ stripeId }),

  userId: "",
  setUserId: (userId) => set({ userId }),

  subscriptionList: [],
  setSubscriptionList: (subscriptionList) => set({ subscriptionList }),

  selectedSubscription: undefined,
  setSelectedSubscription: (selectedSubscription) =>
    set({ selectedSubscription }),

  validPromoCode: undefined,
  setValidPromoCode: (validPromoCode) => set({ validPromoCode }),

  validPromoCodePromo: undefined,
  setValidPromoCodePromo: (validPromoCodePromo) => set({ validPromoCodePromo }),

  validStripeCoupon: undefined,
  setValidStripeCoupon: (validStripeCoupon) => set({ validStripeCoupon }),

  stripeClientSecretId: undefined,
  setStripeClientSecretId: (stripeClientSecretId) =>
    set({ stripeClientSecretId }),

  submitPayment: () => {},
  setSubmitPayment: (submitPayment) => set({ submitPayment }),

  membershipsModalOpen: false,
  setMembershipsModalOpen: (membershipsModalOpen) =>
    set({ membershipsModalOpen }),
}));

export default useWebSignupStore;

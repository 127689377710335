import { IonButton, IonSpinner } from "@ionic/react";
import React from "react";
import { WLCOLORS, WLCOLORSTYPES } from "../../config/global";

import "./WLButton.scss";
import { useFormsStore } from "../../store/common";

type IonButtonProps = React.ComponentProps<typeof IonButton>;
type OverriddenIonButtonProps = Omit<
  IonButtonProps,
  "className" | "color" | "size"
>;

interface WLButtonProps extends Partial<OverriddenIonButtonProps> {
  children: React.ReactNode;
  isFormComponent?: boolean;
  color?: WLCOLORSTYPES;
  className?: string;
  size?: "large" | "small" | "medium"; // default large
}

export const WLButton = (props: WLButtonProps) => {
  const {
    children,
    className,
    color,
    size,
    isFormComponent,
    disabled,
    ...restProps
  } = props;
  const { formLoading } = useFormsStore();
  return (
    <IonButton
      {...restProps}
      className={`wl-button ${!!size ? size : ""} ${
        !!className ? className : ""
      } ca-h4 white`}
      {...(!!color && {
        color: WLCOLORS[color],
      })}
      disabled={disabled || (isFormComponent && formLoading)}
    >
      {isFormComponent && formLoading ? (
        <IonSpinner name="circular" />
      ) : (
        children
      )}
    </IonButton>
  );
};

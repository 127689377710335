import { WebSignUpSteps } from "../../Common/models/analytics";
import { doGet, doPost, encodeQueryData } from "../../Common/utils/https";
import { getRequestHeaders } from "../../Common/utils/https";
import { cloudFunctionsUrl } from "../firebase";
const baseUrl = cloudFunctionsUrl;

export const validateExistingUser = async (emailAddress: string) => {
  const url = `${baseUrl}users/validateExistingUser?${encodeQueryData({
    emailAddress,
  })}`;
  const headers = await getRequestHeaders();
  return doGet(url, headers);
};

export const getUserHashedDetails = async (userId: string) => {
  const url = `${baseUrl}users/getUserHashedDetails?${encodeQueryData({
    userId,
  })}`;
  const headers = await getRequestHeaders();
  return doGet(url, headers);
};

export const trackWebSignUpSteps = async (data: {
  emailAddress: string;
  step: WebSignUpSteps;
}) => {
  const url = `${baseUrl}users/trackWebSignUpSteps`;
  const headers = await getRequestHeaders();
  return doPost(url, headers, data);
};

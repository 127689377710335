import { ENVIRONMENTS } from "../../Common/config/index";
import prodEnv from "./env.production.json";
import stgEnv from "./env.staging.json";
import devEnv from "./env.development.json";
import localEnv from "./env.local.json";
import localProdEnv from "./env.localProduction.json";

export const getEnv = () => {
  var env;

  if (import.meta.env.MODE === ENVIRONMENTS.production) {
    env = prodEnv;
  } else if (import.meta.env.MODE === ENVIRONMENTS.development) {
    env = devEnv;
  } else if (import.meta.env.MODE === ENVIRONMENTS.staging) {
    env = stgEnv;
  } else if (import.meta.env.MODE === ENVIRONMENTS.localProduction) {
    env = localProdEnv;
  } else {
    env = localEnv;
  }
  return env;
};

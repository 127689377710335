import { create } from "zustand";

interface WLLoaderState {
  isLoading: boolean;
  setLoading: (state: boolean, message?: string) => void;

  message: string | null;
}

export const useWLLoaderStore = create<WLLoaderState>((set) => ({
  isLoading: false,
  setLoading: (isLoading, message = "") =>
    set({ isLoading, message: !!isLoading ? message : "" }),

  message: null,
}));

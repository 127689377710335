import cx from "classnames";
import isEmpty from "lodash/isEmpty";
import Lottie from "lottie-react";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonImg,
  IonLabel,
  IonPage,
  IonProgressBar,
  IonToast,
} from "@ionic/react";

import s from "./WebPaymentLanding.module.scss";
import brandLogo from "../../assets/images/brand-logo.svg";
import useWebPaymentLandingStore from "./store";
import successLottie from "../../assets/images/signed_up_lottie.json";
import successAnimation from "../../assets/animations/success-purchase-animation.json";
import { isMobile } from "../../../Common/utils/common";
import { useWebPaymentLandingProvider } from "./provider";
import { WLDownloadButtons } from "./WLDownloadButtons";
import { WLCOLORS } from "../../../Common/config/global";
import { useState } from "react";
import { STRIPE_SUBSCRIPTION_NAME_MAP } from "../../../Common/config";
import { arrowBackOutline, star } from "ionicons/icons";

export const WebPaymentLanding = ({
  redirectToApp,
  onCloseAsModal,
}: {
  redirectToApp?: boolean;
  onCloseAsModal?: () => void;
}) => {
  useWebPaymentLandingProvider(redirectToApp);

  const {
    existingUser,
    signedUpName,
    pid,
    powerUp,
    error,
    paymentMessage,
    paymentError,
    redirectToApp: redirectUserToDownload,
  } = useWebPaymentLandingStore();

  const [animationPlayed, setAnimationPlayed] = useState(false);

  return (
    <IonPage className={s.wlDownloadApp}>
      <IonContent scrollY>
        {!isMobile() && <IonImg src={brandLogo} className={s.brandLogo} />}
        <div className={s.downloadAppContent}>
          <div className={s.main}>
            {redirectUserToDownload && (
              <IonButton
                fill="clear"
                className={s.backBtn}
                onClick={() => {
                  if (onCloseAsModal !== undefined) {
                    onCloseAsModal();
                  }
                }}
              >
                <IonIcon icon={arrowBackOutline} />
              </IonButton>
            )}
            {!existingUser &&
              isEmpty(paymentError) &&
              !redirectUserToDownload && (
                <div
                  className={cx(
                    s.membershipCard,
                    s[STRIPE_SUBSCRIPTION_NAME_MAP[pid]]
                  )}
                >
                  <div className={s.logoContainer}>
                    <div className={s.logo} />
                  </div>
                  <div className={s.membershipDetailsContainer}>
                    <div className={s.membershipNameDetais}>
                      <IonLabel className="wl-small ion-text-uppercase">
                        {signedUpName}
                      </IonLabel>
                      <IonLabel className="wl-small-gilroy">
                        {`${STRIPE_SUBSCRIPTION_NAME_MAP[pid]} Member`}
                      </IonLabel>
                    </div>
                    <div className={s.progressContainer}>
                      <IonProgressBar
                        className={s.progressBar}
                        value={0.8}
                        color={WLCOLORS.success}
                      />
                      <IonLabel className="wl-body-6">
                        Progress 80% completed
                      </IonLabel>
                    </div>
                  </div>
                </div>
              )}
            <div
              className={cx(s.downloadContainer, {
                [s.rediretMode]: !!redirectToApp,
              })}
            >
              {!existingUser && !redirectUserToDownload && (
                <IonLabel className="wl-medium-gilroy gold">
                  Membership unlocked
                </IonLabel>
              )}
              <IonLabel className={cx(s.downloadTitle, "wl-large white")}>
                {paymentMessage || paymentError}
              </IonLabel>
              <IonLabel className="wl-body-6 white">
                {!isEmpty(paymentMessage) && isEmpty(paymentError)
                  ? !powerUp && !existingUser
                    ? !redirectUserToDownload
                      ? "Download the app to complete your profile"
                      : "Open the winner locker app and login to access your account"
                    : "Keep using Winners Locker to track the giveaway and access your exclusive discounts and perks"
                  : !isEmpty(paymentError)
                  ? "Please contact customer support."
                  : ""}
              </IonLabel>
              {!existingUser && isEmpty(paymentError) && (
                <div className={s.subDetails}>
                  <IonLabel className="wl-body-6 white">
                    <IonIcon icon={star} className={s.star} /> Unlock bonus
                    entries to major prize giveaways
                  </IonLabel>
                  <IonLabel className="wl-body-6 white">
                    <IonIcon icon={star} className={s.star} /> Gain access to
                    exclusive perks and discounts
                  </IonLabel>
                  <IonLabel className="wl-body-6 white">
                    <IonIcon icon={star} className={s.star} /> Accumulate in-app
                    rewards points
                  </IonLabel>
                  <IonLabel className="wl-body-6 white">
                    <IonIcon icon={star} className={s.star} /> Open your weekly
                    locker for extra prizes
                  </IonLabel>
                  <IonLabel className="wl-body-6 white">
                    <IonIcon icon={star} className={s.star} /> Plus much more to
                    come
                  </IonLabel>
                </div>
              )}
              <WLDownloadButtons />
            </div>

            {!isEmpty(paymentMessage) &&
              isEmpty(paymentError) &&
              !animationPlayed &&
              !redirectUserToDownload && (
                <div className={s.successAnimationContainer}>
                  <Lottie
                    className={s.successAnimation}
                    animationData={successAnimation}
                    loop={false}
                    onComplete={() => {
                      console.log("completed!");
                      setAnimationPlayed(true);
                    }}
                  />
                </div>
              )}
          </div>
        </div>
      </IonContent>

      <IonToast
        message={error}
        isOpen={!isEmpty(error)}
        duration={2000}
        color={WLCOLORS.danger}
      />
    </IonPage>
  );
};

// Import the functions you need from the SDKs you need
import * as firebase from "firebase/app";
import { Capacitor } from "@capacitor/core";
import { getFirestore } from "firebase/firestore";
import {
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  GoogleAuthProvider as GoogleAuthProviderFB,
  FacebookAuthProvider as FacebookAuthProviderFB,
  signInWithPopup as signInWithPopupFB,
  signInWithRedirect as signInWithRedirectFB,
} from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import { getEnv } from "../env";
const env = getEnv().firebase;

const firebaseConfig = {
  apiKey: env.apiKey,
  authDomain: env.authDomain,
  projectId: env.projectId,
  storageBucket: env.storageBucket,
  messagingSenderId: env.messagingSenderId,
  appId: env.appId,
  measurementId: env.measurementId,
};

export const app = firebase.initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = Capacitor.isNativePlatform()
  ? initializeAuth(app, { persistence: indexedDBLocalPersistence })
  : getAuth(app);
export const storage = getStorage(app);
export const cloudFunctionsUrl = env.cloudFunctionsUrl;

export const stripeKey = getEnv().stripe;
export const wlDomain = getEnv().firebase.authDomain;
export const klaviyoId = getEnv().klaviyoId;
export const googleProvider = new GoogleAuthProviderFB();
export const GoogleAuthProvider = GoogleAuthProviderFB;

export const fbProvider = new FacebookAuthProviderFB();
export const FacebookAuthProvider = FacebookAuthProviderFB;
export const signInWithPopup = signInWithPopupFB;
export const signInWithRedirect = signInWithRedirectFB;

import { create } from "zustand";

interface WebPaymentLandingState {
  pid: string;
  setPid: (value: string) => void;

  existingUser: boolean;
  setExistingUser: (value: boolean) => void;

  redirectToApp: boolean;
  setRedirectToApp: (value: boolean) => void;

  signedUpName: string;
  setSignUpName: (value: string) => void;

  powerUp: boolean;
  setPowerUp: (value: boolean) => void;

  error: string;
  setError: (value: string) => void;

  paymentMessage: string;
  setPaymentMessage: (value: string) => void;

  paymentError: string;
  setPaymentError: (value: string) => void;
}

const useWebPaymentLandingStore = create<WebPaymentLandingState>((set) => ({
  pid: "",
  setPid: (pid) => set({ pid }),

  existingUser: false,
  setExistingUser: (existingUser) => set({ existingUser }),

  redirectToApp: false,
  setRedirectToApp: (redirectToApp) => set({ redirectToApp }),

  signedUpName: "",
  setSignUpName: (signedUpName) => set({ signedUpName }),

  powerUp: false,
  setPowerUp: (powerUp) => set({ powerUp }),

  error: "",
  setError: (error) => set({ error }),

  paymentMessage: "",
  setPaymentMessage: (paymentMessage) => set({ paymentMessage }),

  paymentError: "",
  setPaymentError: (paymentError) => set({ paymentError }),
}));

export default useWebPaymentLandingStore;

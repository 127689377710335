import { create } from "zustand";

export interface FormsState {
  formLoading: boolean;
  setFormLoading: (value: boolean) => void;
}

export const useFormsStore = create<FormsState>((set) => ({
  formLoading: false,
  setFormLoading: (formLoading) => set({ formLoading }),
}));

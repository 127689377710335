import {
  addDoc as addDocFB,
  getDoc as getDocFB,
  getDocs as getDocsFB,
  query as queryFB,
  collection as collectionFB,
  orderBy as orderByFB,
  setDoc as setDocFB,
  doc as docFB,
  updateDoc as updateDocFB,
  onSnapshot as onSnapshotFB,
  where as whereFB,
  deleteDoc as deleteDocFB,
  limit as limitFB,
  deleteField as deleteFieldFB,
  runTransaction as runTransactionFB,
  arrayUnion as arrayUnionFB,
  startAfter as startAfterFB,
  writeBatch as writeBatchFB,
} from "firebase/firestore";

export const addDoc = addDocFB;
export const getDoc = getDocFB;
export const getDocs = getDocsFB;
export const query = queryFB;
export const collection = collectionFB;
export const orderBy = orderByFB;
export const setDoc = setDocFB;
export const doc = docFB;
export const updateDoc = updateDocFB;
export const onSnapshot = onSnapshotFB;
export const where = whereFB;
export const deleteDoc = deleteDocFB;
export const limit = limitFB;
export const deleteField = deleteFieldFB;
export const runTransaction = runTransactionFB;
export const arrayUnion = arrayUnionFB;
export const startAfter = startAfterFB;
export const writeBatch = writeBatchFB;

import React from "react";

import "./WLContent.scss";
import { IonContent } from "@ionic/react";

interface WLContentProps extends React.ComponentProps<typeof IonContent> {
  className?: string;
  children: React.ReactNode;
  scrollable?: boolean;
  darkMode?: boolean;
  contentBGPrimary?: boolean;
  removePadding?: boolean;
}

export const WLContent = (props: WLContentProps) => {
  const {
    className,
    children,
    scrollable,
    darkMode,
    contentBGPrimary,
    removePadding,
  } = props;

  return (
    <>
      <IonContent
        className={`wl-content ${!!darkMode ? "dark-mode" : ""}`}
        scrollY={!!scrollable}
        fullscreen={true}
      >
        <div
          className={`wl-content-main ${scrollable ? "scrollable" : ""} ${
            className ? className : ""
          } ${!!contentBGPrimary ? "primary" : ""} ${
            !!removePadding ? "no-padding" : ""
          }`}
        >
          {children}
        </div>
      </IonContent>
    </>
  );
};

import { TiktokEventCodeType } from "../../Common/models/tiktok";
import { doPost, getRequestHeaders } from "../../Common/utils/https";
import { cloudFunctionsUrl } from "../firebase";
const baseUrl = cloudFunctionsUrl;

/**
 *
 * @param phoneNumber
 * @returns
 */
export const ttqTrackEvent = async (data: {
  event: TiktokEventCodeType;
  event_id?: string;
  user: {
    email: string;
    ttclid?: string;
    phone?: string;
  };
  product: {
    id: string;
    name: string;
    value?: number;
  };
  url: string;
}) => {
  const url = `${baseUrl}tiktokTrack/track`;
  const headers = await getRequestHeaders();
  return doPost(url, headers, data);
};

import { IonLabel } from "@ionic/react";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import upperFirst from "lodash/upperFirst";
import { memo } from "react";
import { WLSkeleton } from "../../../../../Common/components/WLSkeleton/WLSkeleton";
import {
  getStripeAndWLPromoLabel,
  getStripePriceWithPromo,
  getStripePromoLabel,
  getWLPromoDescription,
} from "../../../../services/promo";
import useWebSignupStore from "../../store";
import s from "./styles.module.scss";
import cx from "classnames";
import { WLButton } from "../../../../../Common/components/WLButton/WLButton";

const StripeSubscriptionOverview = () => {
  const {
    selectedSubscription,
    validStripeCoupon,
    validPromoCodePromo,
    setMembershipsModalOpen,
  } = useWebSignupStore();

  const discountPromoLabel =
    validStripeCoupon !== undefined && validPromoCodePromo !== undefined
      ? getStripeAndWLPromoLabel(validStripeCoupon, validPromoCodePromo)
      : "";

  const wlPromoDescription =
    !isNull(validPromoCodePromo) &&
    selectedSubscription !== undefined &&
    !isNull(selectedSubscription) &&
    validPromoCodePromo !== undefined
      ? getWLPromoDescription(validPromoCodePromo, selectedSubscription)
      : "";
  const canChangePlan = true;

  const onChangePlanClick = () => {
    setMembershipsModalOpen(true);
  };

  return (
    <div className={s.container}>
      {selectedSubscription !== undefined ? (
        !isNull(selectedSubscription) ? (
          <div className={s.subscriptionContainer}>
            <div className={s.subscriptionDetails}>
              <IonLabel className={cx(s.subscriptionName, "wl-body-2-gilroy")}>
                {selectedSubscription.name} Member
              </IonLabel>
              <IonLabel
                className={cx(s.subscriptionDescription, "wl-body-2-gilroy")}
              >
                You get
                {` ` + selectedSubscription.entriesPerMonth} entries a month
                into every giveaway.
              </IonLabel>
            </div>

            <div
              className={cx(s.subscriptionPricing, {
                [s.discountedOrWithPromo]: !isEmpty(discountPromoLabel),
              })}
            >
              <div className={s.pricing}>
                {!isNull(validStripeCoupon) && (
                  <IonLabel className="wl-body-3-gilroy">
                    <s>{selectedSubscription.priceString}</s>
                  </IonLabel>
                )}
                <IonLabel className={cx(s.priceLabel, "wl-body-2-gilroy")}>
                  {validStripeCoupon !== undefined && !isNull(validStripeCoupon)
                    ? getStripePriceWithPromo(
                        selectedSubscription.price,
                        validStripeCoupon
                      )
                    : selectedSubscription.priceString}
                  /month
                </IonLabel>
              </div>
              {canChangePlan && (
                <WLButton
                  fill="clear"
                  className={cx(s.changeBtn, "wl-body-2-gilroy ion-no-margin")}
                  onClick={onChangePlanClick}
                >
                  Change
                </WLButton>
              )}
            </div>
          </div>
        ) : (
          <IonLabel className="wl-body-1 danger normal">
            Something went wrong please try again.
          </IonLabel>
        )
      ) : (
        <div>
          <WLSkeleton width="150px" height="24px" />
          <WLSkeleton width="250px" height="32px" />
          <WLSkeleton width="100%" height="14px" />
          <WLSkeleton width="100%" height="14px" />
          <br />
          <br />
          <WLSkeleton width="100%" height="50px" />
        </div>
      )}
      {(!isNull(validPromoCodePromo) || !isNull(validStripeCoupon)) && (
        <div className={s.promoDescriptionContainer}>
          <IonLabel className={cx(s.subscriptionCoupon, "wl-body-2-gilroy")}>
            Promo active:&nbsp;
            {upperFirst(
              `${
                validStripeCoupon !== undefined &&
                !isNull(validStripeCoupon) &&
                selectedSubscription !== undefined &&
                !isNull(selectedSubscription)
                  ? getStripePromoLabel(
                      validStripeCoupon,
                      false,
                      selectedSubscription.price
                    )
                  : ""
              }
            ${
              validStripeCoupon !== undefined &&
              !isNull(validStripeCoupon) &&
              validPromoCodePromo !== undefined &&
              !isNull(validPromoCodePromo)
                ? " & "
                : ""
            }${
                validPromoCodePromo !== undefined &&
                !isNull(validPromoCodePromo)
                  ? wlPromoDescription
                  : ""
              }`.trim()
            )}
          </IonLabel>
        </div>
      )}
    </div>
  );
};

export default memo(StripeSubscriptionOverview);

import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import mobileConfig from "../../mobileConfig.json";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import * as capacitorStorage from "../Common/utils/localStorage";
import { getEnv } from "./env";
import { ENVIRONMENTS, LOCAL_STORAGE } from "../Common/config";
import { getPlatforms } from "@ionic/react";
import { ignoredUnhandledErrors } from "./api/bugsnag";

const env = getEnv();
const bugsnagProperty = env.bugSnag;

Bugsnag.start({
  apiKey: bugsnagProperty.apiKey,
  appVersion: mobileConfig.version,
  plugins: [new BugsnagPluginReact()],
  releaseStage: bugsnagProperty.releaseStage,
  onError: async (event) => {
    if (event.errors.length > 0) {
      // check for ignored unhandled errors then revoke
      const eventError = event.errors[0];
      const message = eventError.errorMessage.toLowerCase().trim();
      const ignoredError = find(
        ignoredUnhandledErrors,
        (error) => error.trim().toLowerCase() === message.toLowerCase().trim()
      );
      if (ignoredError !== undefined) {
        return false;
      }
    }

    const userName = await capacitorStorage.getItem(LOCAL_STORAGE.userName);
    const userId = await capacitorStorage.getItem(LOCAL_STORAGE.userId);
    const userEmail = await capacitorStorage.getItem(LOCAL_STORAGE.userEmail);

    event.setUser(userId || "N/A", userEmail || "N/A", userName || "N/A");
    event.addMetadata("Platform", {
      platform: getPlatforms().join(", "),
    });
    if (import.meta.env.MODE === ENVIRONMENTS.production) {
      const sessionId = await capacitorStorage.getItem(LOCAL_STORAGE.sessionId);
      if (sessionId !== undefined && !isEmpty(sessionId)) {
        event.addMetadata("Session", {
          sessionURL:
            "https://app.sessionstack.com/player/#/sessions/" + sessionId,
        });
      }
    }

    return true;
  },
});

const plugin = Bugsnag.getPlugin("react");
const container = document.getElementById("root");
const root = createRoot(container!);

if (
  plugin !== undefined &&
  [ENVIRONMENTS.local, ENVIRONMENTS.localProduction].indexOf(
    import.meta.env.MODE
  ) === -1
) {
  const ErrorBoundary = plugin.createErrorBoundary(React);
  root.render(
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  );
} else {
  root.render(<App />);
}

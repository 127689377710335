import isEmpty from "lodash/isEmpty";
import { arrowBackOutline, close } from "ionicons/icons";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonLabel,
  IonImg,
  IonBackButton,
} from "@ionic/react";

import cx from "classnames";
import s from "./WLCommonBasicHeader.module.scss";

import { WLCOLORS } from "../../config/global";
import { LANDING } from "../../constants/routes";

interface WLCommonBasicHeaderProps {
  className?: string;
  title?: string;
  overrideBackRoute?: string;
  overrideBackFunction?: () => void;
  onClickClose?: () => void;
  onClickSkip?: () => void;
  noBackButton?: boolean;
  headerClassName?: string;
  darkMode?: boolean;
}

export const WLCommonBasicHeader = (props: WLCommonBasicHeaderProps) => {
  const {
    className,
    title,
    overrideBackRoute,
    overrideBackFunction,
    onClickClose,
    onClickSkip,
    noBackButton,
    headerClassName,
    darkMode,
  } = props;

  const textColor = darkMode ? WLCOLORS.light : WLCOLORS.dark;

  return (
    <IonHeader
      className={cx(s.wlV2Header, headerClassName, darkMode ? s.darkMode : "")}
    >
      <IonToolbar
        className={cx(s.wlHeaderToolbar, "wl-header-gilroy", className)}
      >
        <IonButtons className={s.wlHeaderStartSlotButtons} slot="start">
          {overrideBackRoute !== undefined &&
          !isEmpty(overrideBackRoute) &&
          !noBackButton ? (
            <IonButton
              className={s.headerButton}
              color={textColor}
              routerLink={overrideBackRoute}
              routerDirection="back"
            >
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          ) : overrideBackFunction !== undefined && !noBackButton ? (
            <IonButton
              className={s.headerButton}
              color={textColor}
              onClick={overrideBackFunction}
            >
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          ) : !noBackButton ? (
            <IonBackButton
              color={textColor}
              text=""
              icon={arrowBackOutline}
              defaultHref={LANDING}
            />
          ) : (
            <></>
          )}
        </IonButtons>
        {title && (
          <div style={{ width: "110%", textAlign: "center" }}>
            <IonLabel color={textColor}>{title}</IonLabel>
          </div>
        )}
        {onClickClose && (
          <IonButtons slot="end">
            <IonButton
              className={s.headerButton}
              onClick={onClickClose}
              fill="clear"
              color={textColor}
            >
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        )}
        {onClickSkip && (
          <IonButtons slot="end">
            <IonButton
              className={cx(s.headerButton, "wl-h4 normal")}
              onClick={onClickSkip}
              fill="clear"
              color={textColor}
            >
              SKIP
            </IonButton>
          </IonButtons>
        )}
      </IonToolbar>
    </IonHeader>
  );
};
